const Duke = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 387.469 387.469"
  >
    <path d="m104.515 47.127 10.508 4.585c-7.289 4.463-10.578 8.827-9.746 12.983.856 4.294 4.781 7.996 11.677 11.007 6.352 2.773 13.836 4.274 22.883 4.59 8.921.309 22.683-2.2 40.919-7.464 4.53-1.311 8.081-2.074 10.547-2.264 2.414-.19 4.36.081 6.128.855 1.217.531 4.927 2.147-4.396 6.219-6.056 2.643-12.327 3.731-18.635 3.235-4.991-.389-10.045.531-12.915 2.356l-5.603 3.563c-2.449 1.556-2.945 3.546-1.33 5.296 1.612 1.75 5.131 3.028 9.364 3.408 13.836 1.242 27.493-.351 40.614-4.712l14.184 6.193c5.041 2.2 13.207 2.2 18.246.005 5.037-2.201 5.037-5.767 0-7.966l-14.678-6.408c6.447-4.096 9.838-8.236 10.076-12.331.284-4.791-3.586-8.973-11.499-12.428-5.648-2.466-12.09-3.992-19.136-4.534-7.047-.547-13.727-.242-19.842.905-6.033 1.134-15.215 3.422-27.235 6.786-5.231 1.449-8.779 2.188-10.557 2.199-1.699.009-3.222-.26-4.402-.777-4.188-1.824-.669-4.131 3.02-5.741 4.58-1.999 9.467-2.943 14.522-2.81 5.068.134 9.848-1.049 12.199-3.011l3.25-2.711c2.056-1.714 1.946-3.77-.287-5.444-2.231-1.671-6.276-2.727-10.708-2.793-9.619-.138-18.896 1.148-28.23 3.913l-10.694-4.667c-5.04-2.2-13.207-2.2-18.246 0-5.034 2.198-5.034 5.764.002 7.963z" />
    <path d="M326.447 104.012a46.216 46.216 0 0 0 1.291-10.834V81.59c0-23.154-17.287-44.325-48.677-59.612C249.96 7.805 211.49 0 170.738 0 129.986 0 91.517 7.805 62.415 21.978 31.025 37.265 13.738 58.436 13.738 81.59v11.588c0 22.833 16.82 43.733 47.387 58.968-.848 3.534-1.395 7.938-1.395 10.782v2.114c-29.688 15.173-45.992 35.786-45.992 58.27V234.9c0 15.613 7.871 30.321 22.616 42.936-1.995 5.332-3.035 10.833-3.035 16.455v11.588c0 23.154 17.287 44.325 48.677 59.612 29.102 14.173 67.571 21.978 108.323 21.978 40.752 0 79.222-7.805 108.323-21.978 31.39-15.287 48.677-36.458 48.677-59.612v-11.588c0-15.61-7.864-30.316-22.603-42.929 1.993-5.333 3.021-10.839 3.021-16.462v-2.114c29.688-15.173 45.992-35.786 45.992-58.271v-11.588c.001-22.806-16.781-43.684-47.282-58.915zM35.679 71.784c4.547-11.561 16.932-22.786 35.493-31.825C97.6 27.088 132.96 20 170.738 20c37.778 0 73.139 7.088 99.566 19.959 18.562 9.039 30.946 20.265 35.493 31.825-16.064 27.942-71.876 48.316-135.06 48.316S51.742 99.727 35.679 71.784zm0 141.723c3.697-9.399 12.601-18.569 25.769-26.53 5.073 18.229 21.303 34.656 46.96 47.151 29.102 14.173 67.571 21.978 108.323 21.978 3.983 0 7.938-.097 11.871-.244-17.791 3.826-37.438 5.962-57.863 5.962-63.184-.001-118.997-20.375-135.06-48.317zm289.7 70.978c-16.064 27.942-71.876 48.316-135.06 48.316-54.888 0-104.183-15.383-126.718-37.738 28.959 13.815 66.928 21.427 107.137 21.427 40.752 0 79.222-7.805 108.323-21.978 15.078-7.344 26.892-16.047 35.109-25.674 5.303 5.023 9.105 10.297 11.209 15.647zM216.73 201.439c-55.166 0-104.709-15.532-127.088-38.071 24.167 7.414 52.041 11.4 81.096 11.4 40.752 0 79.222-7.805 108.323-21.977 17.622-8.582 30.798-19.019 38.967-30.619 17.591 8.885 29.359 19.757 33.762 30.951-16.063 27.941-71.876 48.316-135.06 48.316z" />
  </svg>
)
export default Duke
