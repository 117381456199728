import { SvgIcon, SvgIconProps } from "@mui/material"
import { ForwardedRef, forwardRef } from "react"

const CardDeck = forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 -9 50 50"
    {...props}
  >
    <title>{"casino-cards"}</title>
    <path
      d="M35.321 5.323c.01.112.017.224.017.339v1.854l8.776 3.193c1.1.401 1.643 1.666 1.217 2.836l-6.65 18.273c-.427 1.17-1.656 1.79-2.756 1.39l-8.743-3.182h-5.225a3.69 3.69 0 0 0 1.205.723l12.5 4.55a3.68 3.68 0 0 0 4.726-2.204l7.072-19.43a3.68 3.68 0 0 0-2.204-4.726Z"
      style={{
        strokeWidth: 0.0657878,
        paintOrder: "fill markers stroke",
      }}
      transform="translate(-2.42 -4.564) scale(1.0968)"
    />
    <path
      d="M14.679 5.323 4.744 8.939a3.68 3.68 0 0 0-2.204 4.726l7.072 19.43a3.68 3.68 0 0 0 4.726 2.204l12.5-4.55a3.69 3.69 0 0 0 1.205-.723h-5.225l-8.743 3.182c-1.1.4-2.33-.22-2.755-1.39L4.669 13.545c-.426-1.17.117-2.435 1.217-2.836l8.776-3.193V5.662c0-.115.007-.227.017-.34z"
      style={{
        strokeWidth: 0.0657878,
        paintOrder: "fill markers stroke",
      }}
      transform="translate(-2.42 -4.564) scale(1.0968)"
    />
    <path
      d="M35.338 12.774v2.077c.203.01.416.053.639.134 1.846.672 1.583 2.53.596 3.215-.373.262-.8.45-1.235.634V21.1c.72-.548 1.642-.683 2.876-1.574 1.189-.864 1.456-2.51 1.254-3.515-.238-1.172-1.11-2.357-2.793-2.97a4.474 4.474 0 0 0-1.337-.268zM14.662 12.75c-.428.032-.88.126-1.353.298-2.011.732-2.916 2.545-2.87 4.254l2.138.105c-.025-.85.235-1.982 1.445-2.422a2.6 2.6 0 0 1 .64-.15z"
      style={{
        strokeWidth: 0.76155,
      }}
      transform="translate(-2.42 -4.564) scale(1.0968)"
    />
    <path
      d="M18.35 1.974a3.68 3.68 0 0 0-3.688 3.688v20.676a3.68 3.68 0 0 0 3.687 3.688h13.302a3.68 3.68 0 0 0 3.687-3.688V5.662a3.68 3.68 0 0 0-3.687-3.688zm.467 2.055h12.366c1.171 0 2.114 1.003 2.114 2.248v19.446c0 1.245-.943 2.248-2.114 2.248H18.817c-1.171 0-2.114-1.003-2.114-2.248V6.277c0-1.245.943-2.248 2.114-2.248z"
      style={{
        strokeWidth: 0.0657878,
        paintOrder: "fill markers stroke",
      }}
      transform="translate(-2.42 -4.564) scale(1.0968)"
    />
    <path
      d="M24.29 16.267c.587-1.059 1.714-1.683 2.37-2.62.692-.983.304-2.818-1.66-2.818-1.288 0-1.92.975-2.187 1.782l-1.972-.83c.54-1.622 2.01-3.016 4.15-3.016 1.79 0 3.016.815 3.64 1.836.534.875.846 2.513.024 3.731-.914 1.348-1.79 1.76-2.262 2.628-.19.35-.267.578-.267 1.705h-2.2c-.008-.594-.1-1.56.365-2.398m2.231 5.445c0 .837-.685 1.523-1.523 1.523a1.528 1.528 0 0 1-1.523-1.523c0-.838.686-1.523 1.523-1.523.838 0 1.523.685 1.523 1.523"
      style={{
        strokeWidth: 0.76155,
      }}
      transform="translate(-2.42 -4.564) scale(1.0968)"
    />
  </SvgIcon>
))

export default CardDeck
