const Contessa = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 611.999 611.999"
  >
    <path d="M605.176 182.723c-6.856-6.565-17.145-7.994-25.563-3.556l-103.124 54.61-18.909-55.96a22.038 22.038 0 0 0-13.068-13.562 22.067 22.067 0 0 0-18.777 1.474l-57.708 32.992-41.886-93.93a22.062 22.062 0 0 0-40.29 0l-41.887 93.929-57.708-32.992a22.067 22.067 0 0 0-18.777-1.474 22.038 22.038 0 0 0-13.068 13.562l-18.909 55.96-103.124-54.61c-8.4-4.438-18.706-3.009-25.571 3.556a22.052 22.052 0 0 0-4.677 25.386l141.975 299.559a22.046 22.046 0 0 0 19.933 12.609h283.941a22.061 22.061 0 0 0 19.933-12.609l141.966-299.559c4.06-8.594 2.18-18.821-4.702-25.385zm-180.809 34.606 12.636 37.36-35.604 18.848-15.309-34.325 38.277-21.883zm-236.742 0 38.278 21.892-15.318 34.316-35.595-18.848 12.635-37.36zm268.138 212.945H156.239L70.445 249.245l140.351 74.332a22.038 22.038 0 0 0 17.515 1.359 22.08 22.08 0 0 0 12.953-11.868l64.731-145.134 64.731 145.134a22.058 22.058 0 0 0 30.468 10.509l140.351-74.332-85.782 181.029z" />
  </svg>
)
export default Contessa
