const Assassin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 42.072 42.072"
  >
    <path d="M41.594 16.129H30.725c-.552 0-.984.458-1.148.986a3.314 3.314 0 1 1-5.296-3.525c.422-.355.713-.945.541-1.47L21.344 1.418c-.171-.525-.447-.525-.618 0l-3.445 10.603c-.171.525.089 1.179.454 1.593.512.58.824 1.34.824 2.175a3.315 3.315 0 0 1-3.315 3.315 3.294 3.294 0 0 1-3.033-2.012c-.216-.508-.696-.964-1.249-.964H.478c-.553.001-.638.264-.191.589l9.294 6.752c.447.324 1.134.267 1.632.027a3.251 3.251 0 0 1 1.418-.324 3.315 3.315 0 1 1 0 6.629c-.185 0-.366-.019-.541-.052-.295-.055-.663.287-.833.812l-3.235 9.956c-.171.525.053.688.5.363l8.909-6.473c.447-.324.569-.94.423-1.435a3.253 3.253 0 0 1-.133-.92 3.315 3.315 0 1 1 6.497.92c-.146.494-.024 1.11.423 1.435l8.909 6.473c.446.324.671.162.5-.363l-3.209-9.875c-.171-.525-.485-.902-.709-.871a2.7 2.7 0 0 1-.405.03 3.314 3.314 0 1 1 0-6.629c.447 0 .87.092 1.258.256.508.215 1.211.256 1.658-.069l9.143-6.642c.447-.325.36-.588-.192-.588zm-20.56 9.075a2.893 2.893 0 1 1 0-5.786 2.893 2.893 0 0 1 0 5.786z" />
  </svg>
)
export default Assassin
