const Captain = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 512.01 512.01"
  >
    <path d="M366.497 372.762a8.544 8.544 0 0 0-8.055-5.828l-71.27-.316-21.128-64.7 15.565-11.315V.001h-51.2v290.603l15.556 11.315-21.129 64.7-71.27.316a8.526 8.526 0 0 0-8.047 5.828 8.513 8.513 0 0 0 2.918 9.498l57.097 43.418-25.865 75.008a8.547 8.547 0 0 0 3.046 9.685 8.512 8.512 0 0 0 10.146-.077l63.147-47.36 63.147 47.36a8.456 8.456 0 0 0 5.12 1.707 8.534 8.534 0 0 0 5.026-1.63 8.544 8.544 0 0 0 3.038-9.685l-25.856-75.008 57.088-43.418a8.53 8.53 0 0 0 2.926-9.499z" />
    <path d="m157.118 237.303 56.218 40.892V0h-51.2a8.536 8.536 0 0 0-8.533 8.533V230.4a8.536 8.536 0 0 0 3.515 6.903zM358.405 230.404V8.538a8.53 8.53 0 0 0-8.533-8.533h-51.2v278.187l56.218-40.883a8.548 8.548 0 0 0 3.515-6.905z" />
  </svg>
)
export default Captain
